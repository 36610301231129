import {
    Livewire,
    Alpine,
} from "../../vendor/livewire/livewire/dist/livewire.esm";

import calc_relative_time from "./tvmv/relative_time.js";
import calcDuration from "./tvmv/format_duration.js";

// import function to register Swiper custom elements
import { register } from "swiper/element/bundle";
// register Swiper custom elements
register();

import Clipboard from '@ryangjchandler/alpine-clipboard';

import "../../public/vendor/statamic/frontend/js/helpers.js";
import Precognition from "laravel-precognition-alpine";

// import any alpine components, stores or data
import "./alpine/BackButton.js";
import "./alpine/Carousel.js";
import "./alpine/ImageComparison.js";
import "./alpine/LatestNews.js";
import "./alpine/LatestNewsPage.js";
import "./alpine/ReadingProgress.js";
import "./alpine/DatePicker.js";
import "./alpine/TextBox.js";
import "./alpine/TopMenu.js";
import "./alpine/NewsBroadcastPlayer.js";
import "./alpine/VideoPlayer.js";
import "./alpine/VideoTable.js";
import "./alpine/UrlUpdater.js";
import "./alpine/HorizSlider.js";
import "./alpine/ReelsNav.js";
import "./alpine/ReelsPlayer.js";
import "./alpine/Chartbeat.js";
import "./alpine/CookieNotice.js";
import "./alpine/CookieOverview.js";
import "./alpine/ScrollText.js";
import "./alpine/TeaserVideo.js";
import "./alpine/SwiperHelper.js";
import "./alpine/ShareOverlay.js";
import "./alpine/BeyondWords.js";
import "./alpine/CircleProgress.js";

Alpine.plugin(Precognition);
Alpine.plugin(Clipboard);
Livewire.start();

document.addEventListener("DOMContentLoaded", function (event) {
    calc_relative_time();
});

document.addEventListener("DOMContentLoaded", (event) => {
    calcDuration();
});
